import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IMG_URL } from '../Config/api';
import { TopNavbar } from '../Componants/TopNavbar';
import { Navbar } from '../Componants/Navbar';
import { Footer } from '../Componants/Footer';
import { GET_BLOGS } from '../Config/api';

// Function to strip HTML tags
const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

export const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch(GET_BLOGS, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // Strip HTML tags from blog descriptions
                const blogsData = data.map(blog => ({
                    ...blog,
                    description: stripHtmlTags(blog.description)
                }));
                setBlogs(blogsData);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleBlogClick = (blogId) => {
        navigate(`/blogs/${blogId}`);
    };

    return (
        <div>
            <TopNavbar />
            <div className="container-fluid position-relative p-0">
                <Navbar />
                <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: '60px' }}>
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated ">Blogs Page</h1>
                            <Link to="/" className="h6 text-white">Home</Link>
                            <i className="fa fa-angle-right text-white px-2"></i>
                            <span className="h6 text-white">Blogs</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container mb-5">
                    <div className="row g-5">
                        {blogs.map(blog => (
                            <div div key={blog.id} className="col-md-4" onClick={() => handleBlogClick(blog.id)}>
                                <div className="blog-item bg-light rounded overflow-hidden" style={{ maxHeight: '600px' }}>
                                    <div className="position-relative bg-primary overflow-hidden" style={{ height: '200px' }}>
                                        <img src={`${IMG_URL}${blog.image}`} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    </div>
                                    <div className="p-4">
                                        <h5 className="mb-3 text-black"><a className="text-fiord-blue">{blog.title.slice(0, 50)}...</a></h5>
                                        <p className="text-black">{blog.description.slice(0, 100)}...</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mb-4 mt-5">
                        <div className="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 className="mb-0">About Affy Cloud</h3>
                        </div>
                        <div className="bg-light text-center rounded" style={{ padding: '30px' }}>
                            <p className="text-black fs-5">Affy Cloud IT Solution is a hub of innovation, driven by a skilled team dedicated to making a significant impact in strategy, consulting, and infrastructure solutions. We're committed to startups, SMEs, and enterprises, using our unique skills and visionary ideas to create transformative solutions.</p>
                            <Link to="/about"><a className="btn colorb border text-black read border py-2 px-4">Read More</a></Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
